import {
  FontSecondaryRegular,
  FontSecondarySemiBold,
  mediaQueries,
  spacingAround,
  spacingBottom,
  spacingRight,
  spacingTop,
  spacingTopBottom,
  spacingLeft,
  spacingCalculator,
} from '@digando/react-component-library';
import styled from '@emotion/styled';
import {Drawer as DigandoDrawer,} from '../../../../elements/Drawer/Drawer'

export const Drawer = styled(DigandoDrawer)`
  width: 464px !important;
  max-width: 100vw;

  .ant-drawer-content {
    overflow-x: hidden;
  }

  .ant-drawer-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: var(--secondary-background-color);
    min-height: 80px;

    @media ${mediaQueries.mobile} {
      min-height: 99px;
    }
  }

  .ant-drawer-title {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .ant-drawer-close {
    position: relative;
    align-self: center;
    padding-right: 0;
  }

  .ant-drawer-body {
    ${spacingTopBottom(5)};
    ${spacingLeft(8)};
    ${spacingRight(8)};
    overflow: visible;
  }

  .ant-col,
  .ant-input {
    min-width: 100%;
  }

  .ant-form-item {
    margin-top: ${spacingCalculator(1)};
  }

  .ant-select.ant-select-single.ant-select-show-arrow {
    padding: 0;
  }
`;

export const LoginError = styled.div`
  color: var(--color-error);
  ${spacingTop(2)};
  font-size: var(--font-size-text);
`;

export const LabelForm = styled.div`
  ${FontSecondaryRegular};
  ${spacingBottom(1)};
`;

export const RegisterTitle = styled.div`
  ${FontSecondaryRegular};
  ${spacingBottom(2)};
`;

export const RegisterInfo = styled.div`
  display: flex;
  font-size: var(--font-size-text-small);
  ${spacingAround(1)};

  span {
    ${spacingRight(1)};
  }
`;

export const HorizontalLine = styled.hr<{ fullWidth?: boolean }>`
  margin: ${spacingCalculator(4)} ${props => props.fullWidth ? '-64px' : '0'};
  background-color: var(--border-light-color);
`;

export const PasswordRecoverText = styled.div`
  font-size: var(--font-size-text-small);
  text-decoration: underline;
  display: flex;
  justify-content: flex-end;

  a {
    color: var(--black);
  }
`;

export const LoginLink = styled.div`
  font-size: var(--font-size-text);

  div {
    display: flex;
    justify-content: center;
  }

  button {
    display: inline;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    color: var(--black);
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Title = styled.div`
  ${FontSecondarySemiBold};
  font-size: 28px;
  color: var(--secondary-contrast-font-color);

  @media ${mediaQueries.mobile} {
    font-size: var(--font-size-headline-h3);
  }
`;

export const Subtitle = styled.div`
    ${FontSecondaryRegular};
    font-size: var(--font-size-headline-h4);
    color: var(--secondary-contrast-font-color);
    ${spacingTop(1)};
`;

export const ActionContainer = styled.div`
  margin-top: ${spacingCalculator(2)};
  width: 100%;
  text-align: center;
`;
