/**
 * t('form:error.email-not-verified')
 * t('form:error.user-is-disabled')
 * t('form:error.email-is-invalid')
 * t('form:error.wrong-username-or-password')
 * t('form:error.something-went-wrong')
 */

import React, { FC, FormEventHandler, useState } from 'react';
import { Form } from 'antd';
import { IAuthData, useAuthContext } from '../../../../store/auth.context';
import { Icon } from '@digando/react-component-library';
import { useTranslation } from 'next-i18next';
import {
  LabelForm,
  PasswordRecoverText,
  HorizontalLine,
  RegisterTitle,
  RegisterInfo,
  LoginError, ActionContainer,
} from './style';
import { DrawerStep, SignUpStep } from '../../interfaces/drawer.interfaces';
import { SidebarHeader } from '../../components/sidebar-header';
import { useSignUpContext } from '../../../../store/sign-up.context';
import { SidebarContentText } from '../../styles/sidebar';
import { useAuthProcessContentHook } from './hooks/auth-process-content';
import { Button, Input, InputGroup } from '@digando/common-ui';
import { useRecoilValue } from 'recoil';
import { SignInEmailAtom } from './sign-in.store';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';

interface SignInFOrm extends HTMLFormElement {
  email: HTMLInputElement;
  password: HTMLInputElement;
}

export const Header: FC = () => {
  const [t] = useTranslation(['sign-up', 'form']);

  return (
    <SidebarHeader title={t('sign-up:header-title')} subTitle={t('sign-up:header-subtitle')} />
  );
};

export const SignIn: FC<{ fullWidthHorizontalLine?: boolean; onLogin?: () => void }> = ({ fullWidthHorizontalLine = true, onLogin }) => {
  const {
    setIsSignInVisible, isSignInVisible, loading, hasLoginError,
    setHasLoginError, setSignInStep,
  } = useAuthContext();
  const { setSignUpStep } = useSignUpContext();
  const [t] = useTranslation(['sign-up', 'form']);
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const content = useAuthProcessContentHook();
  const email = useRecoilValue(SignInEmailAtom);
  const router = useRouter();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClose = (): void => {
    setIsSignInVisible(false);
  };

  const onSubmit: FormEventHandler<SignInFOrm> = (event): void => {
    event.preventDefault();
    setIsDisabled(true);

    const formValues: IAuthData = {
      email: event.currentTarget.email.value,
      password: event.currentTarget.password.value,
    };

    const runLogin = async (): Promise<void> => {
      setIsLoading(true);

      try {
        setIsDisabled(false);

        form.resetFields();

        const res = await signIn('credentials', {
          callbackUrl: router.asPath, redirect: false, username: formValues.email, password: formValues.password,
        });

        if (!res?.ok) {
          setIsDisabled(false);
          setHasLoginError('wrong-username-or-password');
          setIsLoading(false);

          return;
        }

        if(onLogin) {
          onLogin();
        }

        onClose();
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        setIsDisabled(false);
        setHasLoginError('something-went-wrong');
      }
    };

    runLogin();
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <LabelForm>{t('sign-up:label-form-title')}</LabelForm>
        <InputGroup>
          <Input
            id={'sign-in-email'}
            type='email'
            name={'email'}
            label={t('form:email-address')}
            defaultValue={email}
            onChange={() => {
              setHasLoginError(false);
            }}
          />
        </InputGroup>

        <InputGroup noBottomSpacing={true}>
          <Input
            id={'sign-in-password'}
            type='password'
            name={'password'}
            label={t('form:password')}
            onChange={() => {
              setHasLoginError(false);
            }}
          />
        </InputGroup>

        <PasswordRecoverText>
          <a href={'#'} onClick={(event: React.MouseEvent<HTMLElement>): void => {
            if (!isSignInVisible) {
              setIsSignInVisible(true);
            }

            event.preventDefault();
            setSignInStep(DrawerStep.FORGOT_PASSWORD);
          }}>
            {t('sign-up:password-recovery-title')}
          </a>
        </PasswordRecoverText>

        <ActionContainer>
          <Button
            type={'submit'}
            loading={isLoading || loading}
            disabled={isDisabled}
            fullWidth={true}
          >
            {t('sign-up:login-button-title')}
          </Button>

          {hasLoginError && <LoginError> {t(`form:error.${hasLoginError}`)}</LoginError>}
        </ActionContainer>
      </form>

      <HorizontalLine fullWidth={fullWidthHorizontalLine} />

      {content?.signInIntroText && (
        <SidebarContentText dangerouslySetInnerHTML={{ __html: content.signInIntroText }} />
      )}

      {!content && ( // @todo: DD-1566: Can be removed after content has been maintained on production.
        <>
          <RegisterTitle>{t('sign-up:register-info-title')}</RegisterTitle>
          <RegisterInfo>
            <Icon icon='check' color={`var(--secondary-color)`} size={'24px'} />
            {t('sign-up:register-info-subtitle-first')}
          </RegisterInfo>
          <RegisterInfo>
            <Icon icon='check' color={`var(--secondary-color)`} size={'24px'} />
            {t('sign-up:register-info-subtitle-second')}
          </RegisterInfo>
          <RegisterInfo>
            <Icon icon='check' color={`var(--secondary-color)`} size={'24px'} />
            {t('sign-up:register-info-subtitle-third')}
          </RegisterInfo>
        </>
      )}

      <ActionContainer>
        <Button
          type={'button'}
          onClick={(): void => {
            if (!isSignInVisible) {
              setIsSignInVisible(true);
            }

            setSignUpStep(SignUpStep.SIGN_UP_COMPANY);
            setSignInStep(DrawerStep.SIGN_UP);
          }}
          data-e2e-id={'DigandoSignUpButton'}
          bordered={true}
          fullWidth={true}
        >
          {t('sign-up:register-button-title')}
        </Button>
      </ActionContainer>
    </>
  );
};
