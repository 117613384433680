import styled from '@emotion/styled';
import {
  FontSecondaryLight,
  FontSecondarySemiBold, spacingAround,
  spacingBottom,
  spacingLeft,
} from '@digando/react-component-library';
import check from '../../elements/Icons/Icomoon/svg/check.svg';

export const SidebarContentTitle = styled.h3`
  width: 100%;
  ${FontSecondarySemiBold};
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: var(--secondary-color);
  ${spacingBottom(5)};
`;

export const SidebarContentText = styled.p<{center?: boolean;}>`
  ${FontSecondaryLight};
  font-size: var(--font-size-headline-h4);
  line-height: 24px;
  text-align: ${(p): string => p.center ? 'center' : 'left'};
  color: var(--black);

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    ${spacingLeft(4)}
  }

  // '@fixme: use svg icon

  li {
    position: relative;
    font-size: var(--font-size-text-small);
    line-height: 24px;
    ${spacingAround(1)};
    vertical-align: middle;
    align-items: center;

    &:before {
      position: absolute;
      top: 50%;
      left: -24px;
      width: 24px;
      height: 24px;
      transform: translateY(-50%);
      mask: url(${check.src});
      mask-size: contain;
      font-size: var(--font-size-headline-h3);
      content: "";
      color: var(--secondary-color);
      background-color: var(--secondary-color);
    }
  }
`;
