import styled from '@emotion/styled';
import {
  FontSecondaryRegular,
  FontSecondarySemiBold, Icon,
  mediaQueries, spacingCalculator, spacingLeftRight,
  spacingTop, spacingTopBottom,
} from '@digando/react-component-library';
import { FC } from 'react';
import { useAuthContext } from '../../../store/auth.context';

interface ISidebarHeaderProps {
  title: string;
  subTitle: string;
  onClose?: () => void;
}

export const SidebarHeader: FC<ISidebarHeaderProps> = ({ title, subTitle, onClose }) => {
  const { setIsSignInVisible } = useAuthContext();

  return (
    <SidebarHeaderContainer>
      <Title>{title}</Title>
      <Subtitle>{subTitle}</Subtitle>
      <CloseButton onClick={(): void => onClose ? onClose() : setIsSignInVisible(false)}>
        <Icon icon={'close'} size={'24px'} color={`var(--white)`}/>
      </CloseButton>
    </SidebarHeaderContainer>
  );
};



const SidebarHeaderContainer = styled.div`
  position: relative;
  background-color: var(--secondary-background-color);
  ${spacingTopBottom(2)};
  ${spacingLeftRight(3)};
`;

const Title = styled.div`
  ${FontSecondarySemiBold};
  font-size: 28px;
  line-height: 28px;
  color: var(--secondary-contrast-font-color);

  @media ${mediaQueries.mobile} {
    font-size: 40px;
    line-height: 40px;
  }
`;

const Subtitle = styled.div`
    ${FontSecondaryRegular};
    font-size: var(--font-size-headline-h4);
    color: var(--secondary-contrast-font-color);
    ${spacingTop(1)};

    @media ${mediaQueries.mobile} {
        ${spacingTop(1)};
    }
`;

const CloseButton = styled.div`
  position: absolute;
  z-index: 101;
  right: ${spacingCalculator(3)};
  top: 50%;
  transform: translateY(-50%);
  color: var(--white);
  cursor: pointer;
`;
